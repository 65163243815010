import React from 'react'
import Input from "../Input/Input"
import "./Tabela.css"
import Pagination from '@material-ui/lab/Pagination';
import Modal from '../Modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


class Tabela extends React.Component{
  constructor(props){
    super(props)
    this.state = {show: false}
  }

  renderTbody = () => {
    const lista = this.props.tbody || []
    const listTable = lista.map((list) =>
      <tr>
        {list.map((headers) => {
          return (
            <td key={list.toString()}>
              {headers}
            </td>
          );
        })}
      </tr>
    );
    return listTable;
  }


  renderThead = () => {
    const list = this.props.thead || []
    const listItems = list.map((number) =>
      <th key={number.toString()}>
        {number}
      </th>
    );
    return listItems;
  }

  callModal = () => {
    this.setState({show: true})
  }

  render(){
    return (
      <div id="table">
        <div id="input_table">
          <div className="field_input">
            <h5 id="p_geral">Geral</h5>
            <FontAwesomeIcon icon={faSearch} className="icon" color="#8288AC" />
            <input id="input_search" type="text" placeholder="Insira um código, nome, domínio, perfil ou status"/>
            <button id="btn_search" onClick={() => {this.callModal()}}>PESQUISA AVANÇADA</button>
          </div>
          <Modal show={this.state.show}>
            <Input placeholder="Código"/>
            <Input placeholder="Nome"/>
            <Input placeholder="Domínio"/>
            <Input placeholder="Perfil"/>
            <button className="btn-search">Buscar</button>
          </Modal>
        </div>
        <table id="customers">
          <thead>
            <tr>
              {this.renderThead()}
            </tr>
          </thead>
          <tbody>
            {this.renderTbody()}
          </tbody>
        </table>
        <div id="pagination">
          <Pagination count={4} variant="outlined" color="primary" />
        </div>
      </div>
    )
  }
}

export default Tabela;
