import React from 'react';
import './../../assets/Style.css'
import Tabela from './../../components/Tabela/Tabela'
import Acoes from './../../components/Acoes/Acoes'
import IconAparencia from './../../components/IconAparencia/IconAparencia'
import Menu from '../../components/Menu/Menu'
import Modal from '../../components/Modal/Modal'
import { faEllipsisH, faPencilAlt, faCloudUploadAlt,faPalette } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Aparencia.css"
import MediaQuery from 'react-responsive'

class Aparencias extends React.Component {
  constructor(props){
    super(props)
    this.state = {show: false}
  }

  callModal = () => {
    this.setState({show: true})
  }

  tbody = () => {
    const usuarios = [
      ['AGONZALEZ', 'Projeto x' , <IconAparencia color="#F53C56"/>, <Acoes icon={faPencilAlt} handleClick={()=>this.callModal()} /> , <Acoes icon={faPalette}/>],
      ['Arao', 'Projeto x',  <IconAparencia color="#724FE8"/>,  <Acoes icon={faPencilAlt} handleClick={()=>this.callModal()} />,<Acoes icon={faPalette}/>],
      ['AGONZALEZ', 'Projeto x',  <IconAparencia color="#FF4E00"/>, <Acoes icon={faPencilAlt} handleClick={()=>this.callModal()} />,<Acoes icon={faPalette}/>],
      ['Arao', 'Projeto x', <IconAparencia color="#3CD7F5"/>, <Acoes icon={faPencilAlt} handleClick={()=>this.callModal()} />, <Acoes icon={faPalette}/>],
      ['AGONZALEZ', 'Projeto x', <IconAparencia color="#F5E83C"/>, <Acoes icon={faPencilAlt} handleClick={()=>this.callModal()} />, <Acoes icon={faPalette}/>],
      ['Arao', 'Projeto x',  <IconAparencia color="#3CF59F"/>,<Acoes icon={faPencilAlt} handleClick={()=>this.callModal()} />, <Acoes icon={faPalette}/>],
      ['AGONZALEZ', 'Projeto x',  <IconAparencia color="#3CF59F"/>, <Acoes icon={faPencilAlt} handleClick={()=>this.callModal()} />, <Acoes icon={faPalette}/>],
   ]
    return usuarios;
  }

  tbodyMobile = () => {
    const usuarios = [
      ['23/03/2020',<Acoes icon={faEllipsisH}/> ,<Acoes icon={faPalette}/>,],
      ['23/03/2020', <Acoes icon={faEllipsisH}/> ,<Acoes icon={faPalette}/>],
      ['23/03/2020', <Acoes icon={faEllipsisH}/>,<Acoes icon={faPalette}/>],
   ]
    return usuarios;
  }

  render(){
    return (
      <div className="main">
        <Modal show={this.state.show}>
          <span className="title" label="Dominio: ">viasul</span><hr className="line-title"/>
          <div className="window">
            <div className="section">
              <div className="input-section">
                <span className="label">Escolha a sua cor</span>
                <input type="text" className="hex-color" placeholder="....." />
              </div>

              <div className="input-section">
                <span className="label">Cores predefinidas</span>
                <ul className="cores">
                  <li className="cor1"></li>
                  <li className="cor2"></li>
                  <li className="cor3"></li>
                </ul>
              </div>

            </div>
            <div className="section">

              <div className="input-section">
                <span className="label">Logo do Cabeçalho</span>

                <div class="zone">
                  <div>
                    <FontAwesomeIcon icon={faCloudUploadAlt} color="#4D4F5C" size="3x"/>
                    <div>Drag and drop a file here</div>
                    <span>or</span>
                  </div>

                  <div class="selectFile">
                    <label for="file">ANEXAR ARQUIVO</label>
                    <input type="file" name="files[]" id="file" />
                  </div>
                </div>

              </div>

              <div className="input-section">
                <span className="label">Imagem de Fundo</span>

                <div class="zone">
                  <div>
                    <FontAwesomeIcon icon={faCloudUploadAlt} color="#4D4F5C" size="3x"/>
                    <div>Drag and drop a file here</div>
                    <span>or</span>
                  </div>

                  <div class="selectFile">
                    <label for="file">ANEXAR ARQUIVO</label>
                    <input type="file" name="files[]" id="file" />
                  </div>
                </div>

              </div>
            </div>

            <div className="section">
              <button className="btn_aparencia btn-primary">SALVAR</button>
              <button className="btn_aparencia btn-secondary">CANCELAR</button>
            </div>
          </div>
        </Modal>
        <Menu/>
        <div className="info">
          <div className="painel">
          <MediaQuery query="(max-device-width: 650px)">
            <Tabela thead={['Domínio', 'Conteúdo', 'Editar Descrição | Aparência']} tbody={this.tbodyMobile()} />
          </MediaQuery>
          <MediaQuery query="(min-device-width: 651px)">
            <Tabela thead={['DOMÍNIO', 'DESCRIÇÃO', 'APARÊNCIA', 'EDITAR DESCRIÇÃO', 'EDITAR APARÊNCIA']} tbody={this.tbody()} />
          </MediaQuery>
          </div>
        </div>
      </div>
    )
  }
}

export default Aparencias;
