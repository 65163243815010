import React from 'react';
import './../../assets/Style.css'
import Status from './../../components/Status/Status'
import Header from './../../components/Header/Header'
import SideBar from './../../components/SideBar/SideBar'
import Tabela from './../../components/Tabela/Tabela'
import Acoes from './../../components/Acoes/Acoes'
import Input from './../../components/Input/Input'
import ButtonAdicionar from './../../components/ButtonAdicionar/ButtonAdicionar'
import MiniSideBar from '../../components/MiniSidebar/MiniSideBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH,faEllipsisV, faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import Menu from '../../components/Menu/Menu'
import "./NovaNota.css"
import MediaQuery from 'react-responsive'


class NovaNota extends React.Component {

  constructor(props){
    super(props)
  }

  tbody = () => {
    const usuarios = [
      ['A1', 'CORRECTIVA EMERGENTE', <Status  status="Inativo" color="#F53C56"/>, <Acoes icon={faEllipsisV}/>],
      ['A2', 'CORRECTIVA PROGRAMADA',<Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['C1', 'Emergency Corrective', <Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['A3', 'Preventiva/Preditiva', <Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['C2', 'Schedule Corrective', <Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['AB', 'Serviço eventual', <Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>]
   ]
    return usuarios;
  }

  tbodyMobile = () => {
    const usuarios = [
      ['admin',<Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      ['admin_quito', <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      ['user', <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
      ['user', <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
      ['user', <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
      ['user', <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
   ]
    return usuarios;
  }

  show = () => {
    var content = document.getElementById("collapse");
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="content">
          <div className="left">
            <div className="conteudo">
              <MediaQuery query="(max-device-width: 650px)">
                <div onClick={() => this.show()} className="header_div">
                  <Header title="ADICIONAR NOVA NOTA" icon={faUserPlus}/>
                </div>
               </MediaQuery>
               <MediaQuery query="(min-device-width: 651px)">
                  <Header title="ADICIONAR NOVA NOTA" icon={faUserPlus}/>
               </MediaQuery>
              <div id="collapse">
                <h4>Informações</h4>
                <Input placeholder="Id *"/>
                <textarea placeholder="Descrição *" className="textarea"/>
                <ButtonAdicionar/>
              </div>
            </div>
          </div>
          <div className="tabela">
            <MediaQuery query="(max-device-width: 650px)">
              <Tabela thead={['Código', 'Conteúdo', 'Ações']} tbody={this.tbodyMobile()} />
            </MediaQuery>
            <MediaQuery query="(min-device-width: 651px)">
              <Tabela thead={['Código', 'Descrição', 'Status', 'Ações']} tbody={this.tbody()} />
            </MediaQuery>
          </div>
        </div>
      </div>
    )
  }
}

export default NovaNota;
