import React from 'react';
import './../../assets/Style.css'
import "./NovoDominio.css"

import Header from './../../components/Header/Header'
import Tabela from './../../components/Tabela/Tabela'
import Status from './../../components/Status/Status'
import Acoes from './../../components/Acoes/Acoes'
import Input from './../../components/Input/Input'
import ButtonAdicionar from './../../components/ButtonAdicionar/ButtonAdicionar'
import Menu from '../../components/Menu/Menu'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH,faEllipsisV, faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import MediaQuery from 'react-responsive'



class NovoDominio extends React.Component {
  constructor(props){
    super(props)
  }

  tbody = () => {
    const usuarios = [
      ['A1', '456','Projeto x', <Status status="Inativo" color="#F53C56"/>, <Acoes/>],
      ['A2', '34534','Projeto x',<Status  status="Ativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['C1', '46745', 'Projeto x',<Status  status="Ativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['A3', '23423', 'Projeto x',<Status  status="Ativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['C2', '456', 'Projeto x',<Status  status="Ativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['AB', '2131', 'Projeto x',<Status  status="Ativo" color="#2DCE98"/>,  <Acoes icon={faEllipsisV}/>]
   ]
    return usuarios;
  }

  tbodyMobile = () => {
    const usuarios = [
      ['admin',<Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      ['admin_quito', <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      ['user', <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
   ]
    return usuarios;
  }

  show = () => {
    var content = document.getElementById("collapse");
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="content">
          <div className="left">
            <div className="conteudo">
              <MediaQuery query="(max-device-width: 650px)">
                 <div onClick={() => this.show()} className="header_div">
                  <Header title="ADICIONAR NOVO DOMÍNIO" icon={faUserPlus}/>
                 </div>
               </MediaQuery>
               <MediaQuery query="(min-device-width: 651px)">
                <Header title="ADICIONAR NOVO DOMÍNIO" icon={faUserPlus}/>
               </MediaQuery>
              <div id="collapse">
                <Input placeholder="Nome*"/>
                <Input placeholder="Código*"/>
                <textarea placeholder="Descrição*" className="textarea"/>
                <Input placeholder="Status"/>
                <ButtonAdicionar/>
              </div>
            </div>
          </div>
          <div className="tabela">
            <MediaQuery query="(max-device-width: 650px)">
              <Tabela thead={['Código', 'Conteúdo', 'Ações']} tbody={this.tbodyMobile()} />
            </MediaQuery>
            <MediaQuery query="(min-device-width: 651px)">
              <Tabela thead={['Nome', 'Código', 'Descrição', 'Status', 'Ações']} tbody={this.tbody()} />
            </MediaQuery>
          </div>
        </div>
      </div>
    )
  }
}

export default NovoDominio;
