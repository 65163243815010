import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import NovoUsuario from './pages/Usuarios/NovoUsuario';
import NovoDominio from './pages/Dominio/NovoDominio';
import NovaEmpresa from './pages/Empresa/NovaEmpresa';
import NovoFeriado from './pages/Feriado/NovoFeriado';
import NovoGrupo from './pages/Grupo/NovoGrupo';
import NovaNota from './pages/Nota/NovaNota';
import NovoPerfil from './pages/Perfil/NovoPerfil';
import Informacoes from './pages/InformacoesGerais/Informacoes';
import Aparencias from './pages/Aparencias/Aparencias';
import Geral from './pages/Geral/Geral';
import NotasReenvio from './pages/NotasReenvio/NotasReenvio';

function App() {
  return (
    <main>
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/home" component={Home} />
        <Route path="/novo_usuario" component={NovoUsuario} />
        <Route path="/novo_dominio" component={NovoDominio} />
        <Route path="/nova_empresa" component={NovaEmpresa} />
        <Route path="/novo_feriado" component={NovoFeriado} />
        <Route path="/novo_grupo" component={NovoGrupo} />
        <Route path="/nova_nota" component={NovaNota} />
        <Route path="/novo_perfil" component={NovoPerfil} />
        <Route path="/informacoes" component={Informacoes} />
        <Route path="/aparencias" component={Aparencias} />
        <Route path="/geral" component={Geral} />
        <Route path="/notas_reenvio" component={NotasReenvio} />
      </Switch>
    </main>
  )
}
export default App;
