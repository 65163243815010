import React from 'react';
import './../../assets/Style.css'
import Header from './../../components/Header/Header'
import Status from './../../components/Status/Status'
import Tabela from './../../components/Tabela/Tabela'
import Acoes from './../../components/Acoes/Acoes'
import Input from './../../components/Input/Input'
import ButtonAdicionar from './../../components/ButtonAdicionar/ButtonAdicionar'
import { faEllipsisH,faEllipsisV, faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import Menu from '../../components/Menu/Menu'
import "./NovoFeriado.css"
import MediaQuery from 'react-responsive'


const tbody = () => {
  const usuarios = [
    ['A1', '456','Projeto x', <Status status="Inativo" color="#F53C56"/>, <Acoes icon={faEllipsisV}/>],
    ['A2', '34534','',<Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
    ['C1', '46745', '',<Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
    ['A3', '23423', '',<Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
    ['C2', '456', '',<Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
    ['AB', '2131', '',<Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>]
 ]
  return usuarios;
}

class NovoFeriado extends React.Component {
  constructor(props){
    super(props)
  }

  tbody = () => {
    const usuarios = [
      ['admin', 'Admin', <Status status="Inativo" color="#F53C56"/>, <Acoes icon={faEllipsisV}/>],
      ['adm_quito', 'Perfil de usuário',<Status  status="Inativo" color="#2DCE98"/>, <Acoes icon={faEllipsisV}/>],
      ['AGONZALEZ', 'user', <Status  status="Inativo" color="#2DCE98"/>,  <Acoes icon={faEllipsisV}/>]
   ]
    return usuarios;
  }

  tbodyMobile = () => {
    const usuarios = [
      ['admin',<Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      ['admin_quito', <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      ['user', <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
   ]
    return usuarios;
  }

  show = () => {
    var content = document.getElementById("collapse");
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }
  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="content">
          <div className="left">
            <div className="conteudo">
              <MediaQuery query="(max-device-width: 650px)">
                 <div onClick={() => this.show()} className="header_div">
                  <Header title="ADICIONAR NOVO FERIADO" icon={faUserPlus}/>
                 </div>
               </MediaQuery>
               <MediaQuery query="(min-device-width: 651px)">
                <Header title="ADICIONAR NOVO FERIADO" icon={faUserPlus}/>
               </MediaQuery>
              <div id="collapse">
                <h4>Informações</h4>
                <Input placeholder="Data*"/>
                <Input placeholder="Nome*"/>
                <textarea placeholder="Descrição*" className="textarea"/>
                <Input placeholder="Status"/>
                <div class='row'>
                  <div class='column'>
                    <div class='blue-column'>
                      <input type="checkbox"/>Dia todo
                    </div><br/>
                    <div class='blue-column'>
                    <input type="checkbox"/>Horário comercial início
                    </div>
                  </div>
                  <div class='column'>
                    <div class='green-column'>
                      <input type="checkbox"/>Meio período
                    </div><br/>
                    <div class='green-column'>
                      <input type="checkbox"/>Horário comercial fim
                    </div>
                  </div>
                </div>
                <ButtonAdicionar/>
              </div>
            </div>
          </div>
          <div className="tabela">
            <MediaQuery query="(max-device-width: 650px)">
              <Tabela thead={['Código', 'Conteúdo', 'Ações']} tbody={this.tbodyMobile()} />
            </MediaQuery>
            <MediaQuery query="(min-device-width: 651px)">
              <Tabela thead={['Nome', 'Código', 'Descrição', 'Status', 'Ações']} tbody={tbody()} />
            </MediaQuery>
          </div>
        </div>
      </div>
    )
  }
}

export default NovoFeriado;
