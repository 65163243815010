import React from 'react';
import './../../assets/Style.css'
import Header from './../../components/Header/Header'
import SideBar from './../../components/SideBar/SideBar'
import Status from './../../components/Status/Status'
import Tabela from './../../components/Tabela/Tabela'
import Checkbox from './../../components/Checkbox'
import Input from './../../components/Input/Input'
import Acoes from './../../components/Acoes/Acoes'
import ButtonAdicionar from './../../components/ButtonAdicionar/ButtonAdicionar'
import MiniSideBar from '../../components/MiniSidebar/MiniSideBar'
import Menu from '../../components/Menu/Menu'
import "./NotasReenvio.css"
import { faEllipsisH, faEllipsisV, faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import MediaQuery from 'react-responsive'


const tbody = () => {
  const usuarios = [
    [<Checkbox/>, '' , 'local_quito', 'user','inativo','', '', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '', 'local_quito',  'admin','inativo' ,'', '', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '', 'local_quito', 'user','inativo' ,'', '', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '','local_quito', 'admin', 'inativo','', '', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '','local_quito', 'user', 'inativo','', '', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '', 'local_quito', 'admin', 'inativo','', '', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '', 'local_quito', 'user', 'inativo','', '', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
 ]
  return usuarios;
}
const tbodyMobile = () => {
  const usuarios = [
    [<Checkbox/>, '23434' , <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '543534',  <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '534534', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '65756', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '23', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '23423', <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
    [<Checkbox/>, '213',  <Status  status="Erro no envio" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/> ],
 ]
  return usuarios;
}
class Aparencias extends React.Component {

  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="info">
          <div className="painel_geral">
            <MediaQuery query="(max-device-width: 650px)">
              <Tabela thead={['Ações', 'Número', 'Conteúdo', 'Ações']} tbody={tbodyMobile()} />
            </MediaQuery>
            <MediaQuery query="(min-device-width: 651px)">
              <Tabela thead={['AÇÕES', 'NÚMERO', 'DATA/HORA', 'TIPO', 'LOCAL INSTALAÇÃO', 'EQUIPAMENTO', 'SINTOMA', 'STATUS','AÇÕES']} tbody={tbody()} />
            </MediaQuery>
            <div id="div_btn_reenvio">
              <button id="btn_reenvio">REENVIAR SELECIONADOS</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Aparencias;
