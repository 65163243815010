import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faHome, faFileMedical, faFileImport, faFileAlt, faSignOutAlt, faUserFriends, faIdCardAlt, faPalette, faCalendar, faListAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';
import './SideBar.css'

class SideBar extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }


  render(){
    let toogleMenu = this.props.handleToogle;

    return (
      <div className="sidebar">
        <div className="layer">
          <div className="header">
            <img className="header-logo" src={require('../../assets/img/ccr.svg')} />
            <div className="header-greeting">
              <ul className="greeting list-full">
                <li>Olá,</li>
                <li className="strong">Nome do Usuario</li>
                <li>Editar</li>
              </ul>
            </div>
          </div>
          <hr className="line"/>
          <NavLink to="/home" activeClassName="strong"><span className="home"><i class="gg-home-alt"></i><span>Inicio</span></span></NavLink>
          <br/><p className="p_sidebar"><span>Administração</span></p>
          <ul className="list-full">
            <NavLink to="/novo_usuario" activeClassName="strong"><li><i class="gg-user"></i><span>Usuários</span></li></NavLink>
            <NavLink to="/novo_perfil" activeClassName="strong"><li><i class="gg-shopping-bag"></i><span>Perfis</span></li></NavLink>
            <NavLink to="/nova_nota" activeClassName="strong"><li><i class="gg-file-document"></i><span>Tipos de Nota</span></li></NavLink>
            <NavLink to="/nova_empresa" activeClassName="strong"><li><i class="gg-toolbox"></i><span>Empresas</span></li></NavLink>
            <NavLink to="/novo_dominio" activeClassName="strong"><li><i class="gg-menu-boxed"></i><span>Domínios</span></li></NavLink>
            <NavLink to="/novo_feriado" activeClassName="strong"><li><i class="gg-calendar"></i><span>Feriados</span></li></NavLink>
            <NavLink to="/aparencias" activeClassName="strong"><li><i class="gg-image"></i><span>Aparências</span></li></NavLink>
            <NavLink to="/novo_grupo" activeClassName="strong"><li><i class="gg-display-grid"></i><span>Grupos de Trabalho</span></li></NavLink>
          </ul>
          <p className="p_sidebar"><span>Notas</span></p>
          <ul className="list-full">
            <NavLink to="/informacoes" activeClassName="strong"><li><i class="gg-play-list-add"></i><span>Cadastro</span></li></NavLink>
            <NavLink to="/geral" activeClassName="strong"><li><i class="gg-play-list-search"></i><span>Consulta</span></li></NavLink>
            <NavLink to="/notas_reenvio" activeClassName="strong"><li><i class="gg-play-list-check"></i><span>Reenvio</span></li></NavLink>
          </ul>
          <p className="logout"><i class="gg-log-out"></i><span>Sair</span></p>
        </div>
          <div className="icon_close" onClick={() => toogleMenu()}><i class="gg-chevron-left"></i></div>
      </div>
    )
  }
}

export default SideBar;
