import React from 'react';
import './../../assets/Style.css'
import "./NovoUsuario.css"
import MediaQuery from 'react-responsive'
import Status from './../../components/Status/Status'
import Header from './../../components/Header/Header'
import Acoes from './../../components/Acoes/Acoes'
import Tabela from './../../components/Tabela/Tabela'
import Image from './../../components/Image/Image'
import Input from './../../components/Input/Input'
import ButtonAdicionar from './../../components/ButtonAdicionar/ButtonAdicionar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEllipsisV, faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import Menu from '../../components/Menu/Menu'
import "./NovoUsuario.css"

class NovoUsuario extends React.Component {
  constructor(props){
    super(props)
  }

  tbody = () => {
    const usuarios = [
      ['AGONZALEZ', <Image/> , 'local_quito', 'user',<Status  status="Inativo" color="#F53C56"/>, <Acoes icon={faEllipsisV}/>],
      ['Arao', <Image/>, 'local_quito',  'admin',<Status  status="Inativo" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/>],
      ['AGONZALEZ', <Image/>, 'local_quito', 'user',<Status  status="Inativo" color="#F53C56"/>, <Acoes icon={faEllipsisV}/>],
      ['Arao', <Image/>,'local_quito', 'admin', <Status  status="Inativo" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/>],
      ['AGONZALEZ', <Image/>,'local_quito', 'user', <Status  status="Inativo" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/>],
      ['Arao', <Image/>, 'local_quito', 'admin', <Status  status="Inativo" color="#F53C56"/>, <Acoes icon={faEllipsisV}/>],
      ['AGONZALEZ', <Image/>, 'local_quito', 'user', <Status  status="Inativo" color="#F53C56"/>,  <Acoes icon={faEllipsisV}/>],
   ]
    return usuarios;
  }

  tbodyMobile = () => {
    const usuarios = [
      [<Image/>,<Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      [<Image/>, <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      [<Image/>, <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
      [<Image/>, <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      [<Image/>, <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
   ]
    return usuarios;
  }

   aa = () => {
    var content = document.getElementById("collapse");
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="content">
          <div className="left">
            <div className="conteudo">
             <MediaQuery query="(max-device-width: 650px)">
                <div onClick={() => this.aa()} className="header_div">
                  <Header title="ADICIONAR NOVO USUÁRIO" icon={faUserPlus}/>
                </div>
              </MediaQuery>
              <MediaQuery query="(min-device-width: 651px)">
                <Header title="ADICIONAR NOVO USUÁRIO" icon={faUserPlus}/>
                <div className="geral_empresa">
                  <h3>GERAL</h3>
                  <div class="vl"></div>
                  <h4>Empresa</h4>
                </div>
              </MediaQuery>
              <div id="collapse">
                <h4>Acesso</h4>
                <Input placeholder="Domínio *"/>
                <Input placeholder="Código *"/>
                <Input placeholder="Perfil *"/>
                <Input placeholder="Status"/>
                <h4>Informações pessoais</h4>
                <Input placeholder="Nome *"/>
                <Input placeholder="E-mail *"/>
                <h4>Receber notificação?</h4>
                <label class="label">
                  <div class="toggle">
                    <input class="toggle-state" type="checkbox" name="check" value="check" />
                    <div class="indicator"></div> 
                  </div>
                </label>
                <ButtonAdicionar/>
              </div>
            </div>
          </div>
          <div className="tabela">
            <MediaQuery query="(max-device-width: 650px)">
              <Tabela thead={['Nome', 'Conteúdo', 'Ações']} tbody={this.tbodyMobile()} />
            </MediaQuery>
            <MediaQuery query="(min-device-width: 651px)">
              <Tabela thead={['Código', 'Nome', 'Domínio', 'Perfil', 'Status', 'Ações']} tbody={this.tbody()} />
            </MediaQuery>
          </div>
        </div>
      </div>
    )
  }
}

export default NovoUsuario;
