import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faFileMedical, faFileImport, faFileAlt, faSignOutAlt, faUserFriends, faIdCardAlt, faPalette, faCalendar, faListAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import './MiniSidebar.css'
import '../../assets/Icons.css'
import MediaQuery from 'react-responsive'

class MiniSideBar extends React.Component {

  render(){

    let toogleMenu = this.props.handleToogle;

    return (
      <div className="minisidebar">
        <div className="layer_mini">
        <MediaQuery query="(min-device-width: 651px)">
          <ul className="list-mini">
            <li><img className="logo" width="50%" src={require('../../assets/img/ccr.svg')} /></li><hr className="line-bar"/>
            <Link to="/home"><li className="white_paragraph"><i class="gg-home-alt"></i></li></Link><hr className="line-bar"/>
            <Link to="/novo_usuario"><li><i class="gg-user"></i></li></Link>
            <Link to="/novo_perfil"><li><i class="gg-shopping-bag"></i></li></Link>
            <Link to="/nova_nota"><li><i class="gg-file-document"></i></li></Link>
            <Link to="/nova_empresa"><li><i class="gg-toolbox"></i></li></Link>
            <Link to="/novo_dominio"><li><i class="gg-menu-boxed"></i></li></Link>
            <Link to="/novo_feriado"><li><i class="gg-calendar"></i></li></Link>
            <Link to="/aparencias"><li><i class="gg-image"></i></li></Link>
            <Link to="/novo_grupo"><li><i class="gg-display-grid"></i></li></Link>
          </ul><hr className="line-bar"/>
          <ul className="list-mini">
            <Link to="/informacoes"><li><i class="gg-play-list-add"></i></li></Link>
            <Link to="/geral"><li><i class="gg-play-list-search"></i></li></Link>
            <Link to="/notas_reenvio"><li><i class="gg-play-list-check"></i></li></Link>
          </ul><hr className="line-bar"/>
          <ul className="list-mini">
            <Link to="/informacoes"><li><i class="gg-log-out"></i></li></Link>
          </ul>
          </MediaQuery>
        </div>
        <div className="icon_open" onClick={() => toogleMenu()}><i class="gg-chevron-right"></i></div>
      </div>
    )
  }
}

export default MiniSideBar;
