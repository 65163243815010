import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEllipsisV, faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import './Acoes.css'

export default props => (
  <div className="acoes" onClick={props.handleClick}>
    <FontAwesomeIcon icon={props.icon} />
  </div>
)
