import React from 'react';
import './Home.css'
import './../../assets/Style.css'
import SideBar from './../../components/SideBar/SideBar'
import MiniSideBar from '../../components/MiniSidebar/MiniSideBar'
import Menu from '../../components/Menu/Menu'
import Atalhos from './../../components/ButtonAtalho/ButtonAtalho'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faUserPlus, faHome, faFileMedical, faFileImport, faFileAlt, faSignOutAlt, faUserFriends, faIdCardAlt, faPalette, faCalendar, faListAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons'

class Home extends React.Component {

  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="content">
          <div className="content-main">
            <div className="home_div">
              <img className="quote" src={require('../../assets/img/quote.svg')} alt=""/>
              <h1>Portal para acionamento da equipe técnica de manutenção eletroeletrônica.</h1>
              <h3 className="h3_home">Dúvidas devem ser tratadas pelo ramal 11 5369 ou 14 4069.</h3>
            </div>

            <div className="content_bottom">
              <p id="p_atalhos">Atalhos</p>
              <div className="atalho">
                <Atalhos subtitulo="Novo" titulo="Usuário" icon={"gg-user-add"} color={"#74FFE3"} />
                <Atalhos subtitulo="Novo" titulo="Perfil" icon={"gg-file-add"} color={"#FFDBAF"} />
                <Atalhos subtitulo="Nova" titulo="Empresa" icon={"gg-toolbox"} color={"#91FF99"} />
                <Atalhos subtitulo="Novo" titulo="Domínio" icon={"gg-database"} color={"#FF8EE2"} />
                <Atalhos subtitulo="Cadastrar" titulo="Nova nota" icon={"gg-play-list-add"} color={"#AFCFFF"} />
                <Atalhos subtitulo="Nova" titulo="Aparência" icon={"gg-color-bucket"} color={"#FFC4BD"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home;
