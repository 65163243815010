import React from 'react';
import './Login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

class Login extends React.Component {

  render(){
    return (
      <div className="main_login">
        <div className="login_left">
          <div className="div_image">
            <div className="login_img">
              <img src={require('./../../assets/img/ccr.svg')} id="log" alt="logo"/>
            </div>
          </div>
        </div>
        <div className="login">
          <div className="form">
            <div className="inputs">
              <div className="icones_idioma">
                <img className="idioma" src={require('./../../assets/img/brazil.svg')} />
                <img className="idioma" src={require('./../../assets/img/spain.svg')} />
                <img className="idioma" src={require('./../../assets/img/usa.svg')} />
              </div>
              <p id="p_login">Faça seu login</p>
              <input type="text" className="input_login"/>
              <input type="text" className="input_login" placeholder="Senha"/>
              <p id="p_senha">Esqueci a senha</p>
              <div className="check">
                <div>
                  <input type="checkbox"/>
                  <span className="p_lembrar">Lembrar-se</span>
                </div>
                <div>
                  <input type="checkbox"/>
                  <span className="p_lembrar">Manter conectado</span>
                </div>
              </div>
              <Link to="/home"><button className="btn_login"><FontAwesomeIcon icon={faSignInAlt}/> ACESSAR</button></Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;
