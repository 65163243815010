import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Status.css'

export default props => (
  <div id="div_status">
    <div id="status" style={{background:props.color}}>
   </div>
   <div id="p_status">{props.status}</div>
  </div>

)
