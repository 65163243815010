import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faFileMedical, faFileImport, faFileAlt, faSignOutAlt, faUserFriends, faIdCardAlt, faPalette, faCalendar, faListAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import './ButtonAtalho.css'

export default props => (
  <div className="atalhos">
    <div>
      <span id="span_sub">{props.subtitulo}</span><br/>
      <span id="span_titulo">{props.titulo}</span>
    </div>
    <div className="icons">
      <i class={props.icon} style={{color: props.color, '--ggs': 1.7}}></i>
    </div>
  </div>
)
