import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ButtonAdicionar.css'

export default props => (
  <div className="btn_div">
    <button className="btn add">
      ADICIONAR
    </button>
    <button className="btn limpar">
      LIMPAR
    </button>
  </div>
)
