import React from 'react';
import './../../assets/Style.css'
import Header from './../../components/Header/Header'
import SideBar from './../../components/SideBar/SideBar'
import Input from './../../components/Input/Input'
import ButtonInformacoes from './../../components/ButtonInformacoes/ButtonInformacoes'
import MiniSideBar from '../../components/MiniSidebar/MiniSideBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import Menu from '../../components/Menu/Menu'
import "./Informacoes.css"

class Informacoes extends React.Component {

  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="info">
          <div className="painel">
            <div>
            <h5 id="infos">INFORMAÇÕES GERAIS</h5><hr/>
            <section>
              <div label="Número">
                <div>XXXX</div>
              </div>
              <div label="Nova">
                <div>XXXX</div>
              </div>
              <div label="Empresa">
                <Input placeholder="AUTOBAN"/>
              </div>
              <div label="Tipo de nota">
                <Input placeholder="E1-RODOVIAS CORRETIVAS"/>
              </div>
            </section>
            <section>
              <div label="Descrição">
                <Input placeholder="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est eopksio laborum. Sed ut perspiciatis unde omnis istpoe natus error sit voluptatem accusantium doloremque"/>
              </div>
            </section>
            <section>
              <div label="Local da Instalação">
                <Input placeholder="AUTOBAN"/>
              </div>

              <div label="Equipamento">
                <Input placeholder="AUTOBAN"/>
              </div>
            </section>
            </div>

            <div className="color_div">
              <h5 id="infos">RESPONSABILIDADES</h5><hr/>
              <section>
                <div label="Nome/ Ramal">
                  <Input placeholder="1331"/>
                </div>

                <div label="Prioridade">
                  <Input placeholder="BR - 2 High"/>
                </div>
              </section>
            </div>

            <div>
            <h5 id="infos">SITUAÇÃO</h5><hr/>
            <section>
              <div label="Grupo/ Sintoma">
                <Input placeholder="BC - VAZIO"/> Perfil Vazio
              </div>

              <div label="Sintoma">
                <Input placeholder="0010"/> Selecionar local inst. inferior
              </div>
            </section>

            <section>
              <Input placeholder="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est eopksio laborum. Sed ut perspiciatis unde omnis istpoe natus error sit voluptatem accusantium doloremque"/>
            </section>
            </div>

            <div className="color_div">
              <h5 id="infos">DADOS DE AVARIA</h5><hr/>
              <section>
                <div label="Inicio da Avaria">
                  <Input placeholder="0010"/>
                </div>
                <div label="Parada?">

                </div>
                <div label="Imagem">
                  <Input placeholder=""/>
                </div>
              </section>
            </div>

            <section style={{width:"100%"}}>
              <ButtonInformacoes/>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default Informacoes;
