import React from 'react'
import './Modal.css'

class Modal extends React.Component{
    constructor(props){
        super(props)
        this.state = {}
    }

    componentWillReceiveProps (nextProps) {
        this.setState({show: nextProps.show});
    }

    closeModal = () => {
        this.setState({show: false})
    }

    render(){
        return(
            <div className={this.state.show ? "modal display-block" : "modal display-none"} >
                <div className="modal-window">
                    <div id="close_modal" onClick={() => this.closeModal()}>
                        <h3>X</h3>
                    </div>
                    {React.Children.map(this.props.children, child => {
                    return child
                    })}
                </div>
            </div>
        )
    }
}

export default Modal; 