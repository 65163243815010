import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './ButtonInformacoes.css'

export default props => (
  <div className="btn_info">
    <button className="buttons cadastrar">
      CADASTRAR
    </button>
    <button className="buttons clear">
      LIMPAR
    </button>
  </div>
)
