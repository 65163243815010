import React from 'react';
import './../../assets/Style.css'
import Tabela from './../../components/Tabela/Tabela'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import Menu from '../../components/Menu/Menu'
import "./Geral.css"
import MediaQuery from 'react-responsive'
import Acoes from './../../components/Acoes/Acoes'


class Aparencias extends React.Component {
  constructor(props){
    super(props)
  }

  tbody = () => {
    const usuarios = [
      ['AGONZALEZ', '' , 'local_quito', 'user','inativo'],
      ['Arao', '', 'local_quito',  'admin','inativo'],
      ['AGONZALEZ', '', 'local_quito', 'user','inativo'],
      ['Arao', '','local_quito', 'admin', 'inativo'],
      ['AGONZALEZ', '','local_quito', 'user', 'inativo'],
      ['Arao', '', 'local_quito', 'admin', 'inativo'],
      ['AGONZALEZ', '', 'local_quito', 'user', 'inativo'],
   ]
    return usuarios;
  }
  tbodyMobile = () => {
    const usuarios = [
      [2343423,<Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      [45645645, <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      [45645645, <Acoes icon={faEllipsisH}/>,<Acoes icon={faEllipsisV}/>],
      [45645645, <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
      [45645645, <Acoes icon={faEllipsisH}/> ,<Acoes icon={faEllipsisV}/>],
   ]
    return usuarios;
  }

  render(){
    return (
      <div className="main">
        <Menu/>
        <div className="info">
          <div className="painel_geral">
            <MediaQuery query="(max-device-width: 650px)">
              <Tabela thead={['Número', 'Conteúdo', 'Ações']} tbody={this.tbodyMobile()} />
            </MediaQuery>
            <MediaQuery query="(min-device-width: 651px)">
              <Tabela thead={['DOMÍNIO', 'DESCRIÇÃO', 'APARÊNCIA', 'EDITAR DESCRIÇÃO', 'EDITAR APARÊNCIA']} tbody={this.tbody()} />
            </MediaQuery>
          </div>
        </div>
      </div>
    )
  }
}

export default Aparencias;
