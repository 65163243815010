import React from 'react'
import SideBar from '../SideBar/SideBar'
import MiniSidebar from '../MiniSidebar/MiniSideBar'

class Menu extends React.Component{
    constructor(props){
        super(props)
        this.state = {open: false}
    }

    toogleMenu = () => {
        var content = document.getElementsByClassName("content")[0];
        var painel = document.getElementsByClassName("painel")[0];
        var info = document.getElementsByClassName("info")[0];

        if(this.state.open == false && window.innerWidth < 650) {
          if(content != undefined) content.style.display = "none";
          if(info != undefined) info.style.display = "none";
          if(painel != undefined) painel.style.display = "none";
        }
        else{
          if(info!= undefined) info.style.display = "flex";
          if(content!= undefined) content.style.display = "flex";
          if(painel!= undefined) painel.style.display = "flex";
        }

        let open = this.state.open?false:true
        this.setState({open})
        console.log(this.state.open)
    }

    render(){
        return(
            this.state.open?
                <SideBar handleToogle={this.toogleMenu} />

            :
                <MiniSidebar handleToogle={this.toogleMenu} />
        )
    }
}

export default Menu;
